import {
    Card,
    CardTitle,
    CardText,
} from 'reactstrap';

const TestimonialCard = ({ item }) => {
    return (
        <Card className="text-center text-white bg-transparent border border-0 py-4 px-5 align-items-center justify-content-center">
            <img
                alt="Sample"
                src={item.profile_photo}
                className="rounded-circle mb-3"
                style={{
                    height: "85px",
                    width: "85px"
                }}
            />
            <CardTitle tag="h5">
                {item.name}
            </CardTitle>
            <CardText className="testimonials-text">
                {item.description}
            </CardText>
            <div className="d-flex gap-2 mb-3 stars">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
            </div>
        </Card>
    )
}

export default TestimonialCard