import { useState } from 'react';

import { CarouselItem } from 'reactstrap';

import TestimonialCard from '../../ui/TestimonialCard';
import CorouselWrapper from './CorouselWrapper';

const ReviewSlides = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = data.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.id}
            >
                <TestimonialCard item={item} />
            </CarouselItem>
        );
    });

    return (
        <CorouselWrapper
            className="p-3 rounded-5 bg-primary h-100"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            goToIndex={goToIndex}
            testimonialsData={data}
        >
            {slides}
        </CorouselWrapper>
    )
}

export default ReviewSlides;