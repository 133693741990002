import useFetchData from '../../../hooks/useFetchData';
import ReviewSlides from './ReviewSlides';

const Reviews = () => {
    const [isLoading, data] = useFetchData(process.env.REACT_APP_REVIEWS_ENDPOINT);

    // Return nothing if loading
    if (isLoading)
        return <></>

    return (
        <ReviewSlides data={data} />
    )
}

export default Reviews