import { Row, Col } from 'reactstrap';
import ReviewsText from './ReviewsText';
import Reviews from './Reviews';

const Testimonials = () => {
    return (
        <section className="bg-gray">
            <div className="container py-5 px-sm-4">
                <Row className="justify-content-between">
                    <Col className="mb-sm-4" md="5">
                        <ReviewsText />
                    </Col>
                    <Col md="6">
                        <Reviews />
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Testimonials;

// Single Corousel Item
// => Prepare on slide 

// Single Card
// => prepare single card for testmonials

// Corousel 
// => prepare corousel
// => this component will wrap around slides that we will derive
// => by mapping onto single corousel item