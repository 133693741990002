import { useState, useEffect } from 'react'

function useFetchData(url) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(url, {
                headers: {
                    Authorization: process.env.REACT_APP_API_TOKEN
                }
            });

            const response = await res.json();
            setData(response.results);
            setIsLoading(false);
        }

        fetchData();
    }, [url]);

    return [isLoading, data];
}

export default useFetchData;