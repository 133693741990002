import { Button } from 'reactstrap';

const ReviewsText = () => {
    return (
        <div>
            <h2 className="mb-4">Client Reviews</h2>
            <div className="mb-5">
                Discover what our satisfied travelers have to say about their extraordinary experiences with Backpacker Tours. Our group tours have left an indelible impression, creating cherished memories and forming lifelong bonds.
                Read the testimonials and get inspired to embark on your own adventure in captivating India.
            </div>
            <div className="d-flex gap-3">
                <Button className="rounded-pill py-2 px-4 hover-white" color="primary" outline>
                    Check Out Reviews On<i className="bi bi-google ms-2"></i>
                </Button>
            </div>
        </div>
    )
}

export default ReviewsText;