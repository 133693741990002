import {
    Carousel,
    CarouselIndicators,
    CarouselControl,
} from 'reactstrap';

const CorouselWrapper = ({ activeIndex, next, previous, goToIndex, testimonialsData, children }) => {
    return (
        <Carousel
            className="p-3 rounded-5 bg-primary h-100"
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            <CarouselIndicators
                items={testimonialsData}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
            />
            {children}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
            />
        </Carousel>
    )
}

export default CorouselWrapper